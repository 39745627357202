export interface IMediaDevice extends MediaDeviceInfo {}

export interface IMediaStreamConstraints extends globalThis.MediaStreamConstraints {}

export interface IMediaStream extends globalThis.MediaStream {}

export interface IMediaStreamTrack extends globalThis.MediaStreamTrack {}
export interface IMediaTrackSettings extends MediaTrackSettings {}

// Если пользователь закрыл модалку выбора доступа

export enum MediaPermissionError {
  'None',
  'Unknown',
  'AbortError',
  'NotAllowedError', // Пользователь выбирает не давать доступ в модалке или закрыл доступ
  'NotFoundError',
  'NotReadableError',
  'OverconstrainedError',
  'SecurityError',
  'TypeError',
}
export type MediaPermissionErrorKey = keyof typeof MediaPermissionError

export enum MediaServiceError {
  'NotSupport' = 'MediaNotSupport',
  'NotLocalStream' = 'MediaLocalStreamNotExists',
}
