import { defineStore } from 'pinia'

import { THREAD_LOAD_LIMIT, MESSAGES_LOAD_LIMIT } from '../config'
import type { ChatThread, ChatThreadDialog, ChatFilterSort, ChatThreadMessage } from '../types'

type ChatStoreState = {
  unreadMessagesCount: number
  friendRequestsCount: number
  unreadDialogsCount: number
  openThreadId: number
  openFriendRequest: boolean
  openEnter: string
  filterSort: ChatFilterSort
  filterOnline: boolean
  ready: boolean
  threadsLoading: boolean
  threads: ChatThread[]
  threadsUpIds: number[]
  threadsFixedOnTopIds: number[]
  threadSelectedType: 'dialog' | 'friendRequests' | 'premium' | ''
  threadSelectedId: number
  messagesLoading: boolean
  messagesNoMore: boolean
  messages: ChatThreadMessage[]
  messageInputText: string
}

export const useChatStore = defineStore('chat', {
  state: (): ChatStoreState => {
    return {
      unreadMessagesCount: 0,
      friendRequestsCount: 0,
      unreadDialogsCount: 0,
      openThreadId: 0,
      openFriendRequest: false,
      openEnter: '',
      filterSort: 'recent',
      filterOnline: false,
      ready: false,
      threadsLoading: false,
      threads: [],
      threadsUpIds: [],
      threadsFixedOnTopIds: [],
      threadSelectedType: '',
      threadSelectedId: -1,
      messagesLoading: false,
      messagesNoMore: false,
      messages: [],
      messageInputText: '',
    }
  },
  getters: {
    threadsCanMore: (state) => state.threads.length >= THREAD_LOAD_LIMIT,
    threadSelectedFriendRequests: (state) => state.threadSelectedType === 'friendRequests',
    threadsSort: (state) => {
      const idsUsed = new Set<number>()
      const threadsMap = new Map<number, ChatThread>(
        state.threads.map((thread) => [thread.id, thread]),
      )
      const threads: ChatThread[] = []
      state.threadsFixedOnTopIds.forEach((id) => {
        const thread = threadsMap.get(id)
        if (!thread) return
        idsUsed.add(id)
        threads.push(thread)
      })
      state.threadsUpIds.forEach((id) => {
        if (idsUsed.has(id)) return
        const thread = threadsMap.get(id)
        if (!thread) return
        idsUsed.add(id)
        threads.push(thread)
      })
      state.threads.forEach((thread) => {
        if (idsUsed.has(thread.id)) return
        threads.push(thread)
      })
      return threads
    },
    threadById: (state) => (id: number) => state.threads.find((item) => item.id === id),
    messagesCanMore: (state) => state.messages.length >= MESSAGES_LOAD_LIMIT,
  },
  actions: {
    resetThreads() {
      this.threads = []
      this.threadsUpIds = []
      this.threadsFixedOnTopIds = []
    },
    filterSet(index: number) {
      switch (index) {
        case 0:
          this.filterSort = 'recent'
          this.filterOnline = false
          break
        case 1:
          this.filterSort = 'unread'
          this.filterOnline = false
          break
        case 2:
          this.filterSort = 'recent'
          this.filterOnline = true
          break
      }
    },
    threadPush(thread: ChatThread) {
      this.threads.push(thread)
      if (thread.type === 'newsFeed') {
        this.threadsFixedOnTopIds.push(thread.id)
      }
    },
    threadUpdate(id: number, props: Partial<ChatThread>, bUp: boolean = false): void {
      // update thread
      const index = this.threads.findIndex((item) => item.id === id)
      if (index === -1) return
      // @ts-ignore
      this.threads.splice(index, 1, {
        ...this.threads[index],
        ...props,
      })
      // up if need
      if (!bUp) return
      if (this.threadsFixedOnTopIds.includes(id)) return
      const upIndex = this.threadsUpIds.findIndex((itemId) => itemId === id)
      if (upIndex >= 0) this.threadsUpIds.splice(upIndex, 1)
      this.threadsUpIds.unshift(id)
    },
    threadDelete(id: number) {
      const index = this.threads.findIndex((item) => item.id === id)
      if (index === -1) return
      this.threads.splice(index, 1)
    },
    selectFriendRequests() {
      this.threadSelectedType = 'friendRequests'
    },
    selectPremium(threadId: number) {
      if (this.threadSelectedId === threadId) return
      this.threadSelectedType = 'premium'
      this.threadSelectedId = threadId
    },
    selectThread(threadId: number) {
      if (this.threadSelectedId === threadId) return
      this.threadSelectedType = 'dialog'
      this.threadSelectedId = threadId
      this.messages = []
      this.messagesLoading = false
      this.messagesNoMore = false
      this.messageInputText = ''
    },
    unselectThread() {
      this.threadSelectedType = ''
      this.threadSelectedId = -1
      this.messages = []
      this.messagesLoading = false
      this.messagesNoMore = false
      this.messageInputText = ''
      this.openThreadId = 0
      this.openFriendRequest = false
      this.openEnter = ''
    },
    threadSelectedGet(): ChatThreadDialog | undefined {
      if (!['dialog', 'premium'].includes(this.threadSelectedType)) return undefined
      return this.threads.find((thread) => thread.id === this.threadSelectedId) as ChatThreadDialog
    },
  },
})
