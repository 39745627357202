export const ERROR_SCOPE = 'Chat: '

export const THREAD_LOAD_LIMIT = 15
export const THREAD_AVATAR_WIDTH = 40 * 2
export const THREAD_AVATAR_HEIGHT = 40 * 2

export const MESSAGES_LOAD_LIMIT = 20

// export const EVENTBUS_CONTEXT = 'chat'
// export const EVENTBUS_COMMAND_BASE = 'base'
