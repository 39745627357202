import { defineStore } from 'pinia'

import { type IMediaDevice, type IMediaStream, MediaPermissionError } from '../types'

type MediaStoreState = {
  devices: IMediaDevice[]
  supported: boolean
  startInit: boolean
  permissionShow: boolean
  permissionGranted: boolean
  permissionDenied: boolean
  localStreams: IMediaStream[]
  localStreamInit: boolean
  localStreamError: MediaPermissionError
  localStreamAudioActive: boolean
}

export const useMediaStore = defineStore('media', {
  state: (): MediaStoreState => {
    return {
      devices: [],
      supported: false,
      startInit: false,
      permissionShow: false,
      permissionGranted: false,
      permissionDenied: false,
      localStreams: [],
      localStreamInit: false,
      localStreamError: MediaPermissionError.None,
      localStreamAudioActive: false,
    }
  },
  getters: {
    audioInputDevices: (state) =>
      state.devices.filter((device: IMediaDevice) => device.kind === 'audioinput'),
    videoInputDevices: (state) =>
      state.devices.filter((device: IMediaDevice) => device.kind === 'videoinput'),
    localStream: (state): IMediaStream | undefined =>
      state.localStreams.find((stream) => stream.active),
  },
  actions: {
    localStreamAdd(stream: IMediaStream) {
      this.localStreams.push(stream)
    },
  },
})
