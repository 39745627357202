import income from '@/shared/public/video-call/private-fake-call.mp3'

const audio = ref<HTMLAudioElement>()
const playing = ref(false)

export function useFakeAudio() {
  function play() {
    if (playing.value) return
    audio.value = new Audio(income)
    audio.value.play()
    playing.value = true
  }

  function stop() {
    if (!playing.value || !audio.value) return
    audio.value?.pause()
    audio.value = undefined
    playing.value = false
  }

  return {
    play,
    stop,
  }
}
